import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select as SelectBox,
    Slider,
    Switch,
    Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import CircularLoading from "../../../../components/loading/CircularLoading";
import Wizard from "../../../../components/Wizard";
import { validateProject } from "../util";
import InitiativesDialog from "./InitiativesDialog";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { ColoredCell, EmptyCell, LabelCell, ScaleXCell, ScaleYCell } from "./Style";
import InitiativeDetailsDialog from "../initiative/InitiativeDetailsDialog";
import FullscreenLoading from "../../../../components/loading/FullscreenLoading";
import { formatCompactAmount, formatInteger, formatIrr } from "../../../../util/formatter";
import { useUpdateEffect } from "react-use";
import AdvisorContainer from "../../../../layout/AdvisorContainer";
import { AppContext } from "../../../../AppRouter";

const Prioritize = ({ steps, basePath, originPath }) => {
    const { client, config, notify } = useContext(AppContext);
    const { projectId } = useParams();

    const [projectName, setProjectName] = useState();
    const [projectStatus, setProjectStatus] = useState({});
    const [projectStep, setProjectStep] = useState();

    const [initiatives, setInitiatives] = useState([]);
    const [hasChanges, setHasChanges] = useState(false);

    const [xxMetric, setXXMetric] = useState("simplicity");
    const [yyMetric, setYYMetric] = useState("run_rate");
    const [isFitted, setIsFitted] = useState(true);

    const [firstThreshold, setFirstThreshold] = useState(33);
    const [secThreshold, setSecThreshold] = useState(66);

    const [anchorX, setAnchorX] = useState(null);
    const [anchorY, setAnchorY] = useState(null);

    // identify
    const [openIdentify, setOpenIdentify] = useState(false);
    // edit initiative
    const [initiativeToEdit, setInitiativeToEdit] = useState();
    const [saving, setSaving] = useState(false);
    const [previewing, setPreviewing] = useState(false);

    const [initiativesToSave, setInitiativesToSave] = useState({});

    const xxMetricOptions = ["simplicity", "support"];
    const yyMetricOptions = ["npv", "run_rate", "irr", "break_even"];
    // Defines order of x-axis values
    const xxMetricFilter
        = xxMetric === "simplicity"
            ? [...config.procurement.simplicity]
            : [...config.procurement.support].reverse();

    const colors = [
        ["procurementMatrix1X1Yellow.main", "procurementMatrix1X2Green.main", "procurementMatrix1X3Green.main"],
        ["procurementMatrix2X1Orange.main", "procurementMatrix2X2Yellow.main", "procurementMatrix2X3Green.main"],
        ["procurementMatrix3X1Pink.main", "procurementMatrix3X2Orange.main", "procurementMatrix3X3Yellow.main"],
    ];

    const borderColors = [
        ["procurementMatrix1X1Yellow.dark", "procurementMatrix1X2Green.dark", "procurementMatrix1X3Green.dark"],
        ["procurementMatrix2X1Orange.dark", "procurementMatrix2X2Yellow.dark", "procurementMatrix2X3Green.dark"],
        ["procurementMatrix3X1Pink.dark", "procurementMatrix3X2Orange.dark", "procurementMatrix3X3Yellow.dark"],
    ];

    useEffect(() => {
        if (projectId) {
            client.procurement.procurementGetProject(projectId, true, false)
                .then((project) => {
                    setProjectName(project.name);
                    setProjectStep(project.step);
                    setInitiatives(project.initiatives);

                    validateProject(client, notify, projectId, validateAndSetStatus);
                })
                .catch((error) => {
                    setInitiatives([]);
                    notify.error(error, "procurement.project.projects.fetch");
                });
        } else {
            setInitiatives([]);
        }
    }, [projectId]);

    const preview = (initiativesToPreview) => {
        // Preview is often called inside components
        // Due to how renders things, it won't have the updated initiatives (from setInitiatives)
        // thus we must pass them to preview
        if (!isFitted) {
            return;
        }

        setPreviewing(true);

        const payload = initiativesToPreview.map(initiative => (
            {
                id: initiative.id,
                identified: initiative.identified,
                prioritized: initiative.prioritized,
            }
        ));

        client.procurement.procurementPrioritizeInitiatives(projectId, payload, true)
            .then((project) => {
                project.initiatives.forEach((pi) => {
                    const initiative = initiativesToPreview.find(ii => (ii.id === pi.id));
                    initiative.amended_irr = pi.amended_irr;
                    initiative.amended_npv = pi.amended_npv;
                    initiative.amended_break_even = pi.amended_break_even;
                    initiative.amended_run_rate = pi.amended_run_rate;
                });
                setInitiatives(initiativesToPreview);
                setPreviewing(false);
            })
            .catch((error) => {
                setPreviewing(false);
                notify.error(error, "procurement.project.prioritization");
            });
    };

    useUpdateEffect(() => {
        preview(initiatives);
    }, [isFitted]);

    const validateAndSetStatus = (status) => {
        if (status.prioritize && !status.prioritize.valid) {
            notify.warn("procurement.project.prioritize.not.valid");
        }

        setProjectStatus(status);
    };

    const isValid = () => {
        const valid = [...initiatives].some(initiative => initiative.identified && initiative.prioritized);

        if (!valid) {
            notify.warn("procurement.project.prioritize.not.valid");
        }

        return valid;
    };

    const getYyMetricLabel = (metric, isFitted) => {
        if (isFitted) {
            return config.i18n.procurement.identify["amended_" + metric];
        } else {
            return config.i18n.procurement.identify[metric];
        }
    };

    const getYyMetricValue = (initiative, metric, isFitted) => {
        if (isFitted) {
            return initiative["amended_" + metric];
        } else {
            return initiative[metric];
        }
    };

    const formatYyMetricValue = (initiative, metric, isFitted) => {
        const value = getYyMetricValue(initiative, metric, isFitted);

        switch (metric) {
            case "irr":
                return formatIrr(value, config.locale, config.i18n.infinity);
            case "break_even":
                return formatInteger(value, config.locale);
            case "npv":
            case "run_rate":
            default:
                return formatCompactAmount(value, config.locale);
        }
    };

    const getInitiativeItem = (identifiedInitiatives, xValue, lowerIndex, upperIndex) => {
        return [...identifiedInitiatives]
            .filter((initiative, index) => initiative[xxMetric] === xValue && index >= lowerIndex && index < upperIndex)
            // sort DESC to ensure the initiatives are ordered inside each cell.
            .sort((a, b) => getYyMetricValue(b, yyMetric, isFitted) - getYyMetricValue(a, yyMetric, isFitted))
            .map((initiative) => {
                return (
                    <ListItem
                        key={"item-" + initiative.id}
                        disablePadding
                    >
                        <ListItemButton
                            key={"item-" + initiative.id}
                            onClick={() => setInitiativeToEdit(initiative)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={initiative.prioritized}
                                    onClick={e => e.stopPropagation()}
                                    onChange={(e) => {
                                        initiative.prioritized = e.target.checked;
                                        const newInitiatives = [...initiatives];
                                        setInitiatives(newInitiatives);
                                        setHasChanges(true);
                                        preview(newInitiatives);
                                    }}
                                    sx={{ p: 0 }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={(
                                    <>
                                        <Typography sx={{ fontSize: "small", ml: -3 }}>
                                            {initiative.name}
                                        </Typography>
                                        <Typography sx={{ fontSize: "small", ml: -3 }}>
                                            {isFitted
                                                ? (
                                                        formatYyMetricValue(initiative, yyMetric, true) + " / "
                                                        + formatYyMetricValue(initiative, yyMetric, false)
                                                    )
                                                : (
                                                        formatYyMetricValue(initiative, yyMetric, false)
                                                    )}
                                        </Typography>
                                    </>
                                )}
                            />
                        </ListItemButton>
                    </ListItem>
                );
            });
    };

    if (!projectName || !initiatives) {
        return (
            <AdvisorContainer>
                <CircularLoading flex={1} label={config.i18n.procurement.prioritize.loading} />
            </AdvisorContainer>
        );
    }

    const identifiedInitiatives = initiatives
        .filter(initiative => initiative.identified);
    // we need to sort the initiatives ASC, to calculate the thresholds.
    identifiedInitiatives.sort((a, b) => getYyMetricValue(a, yyMetric, isFitted) - getYyMetricValue(b, yyMetric, isFitted));

    // reverse when sorting by break even, since a smaller one should be on the top thresholds.
    // smaller break even is better, while the other metrics, smaller is worse.
    if (yyMetric === "break_even") {
        identifiedInitiatives.reverse();
    }

    // set the indexes to split, by rows.
    let firstThresholdIndex = 0;
    let secThresholdIndex = 0;
    let maxIndex = 0;
    if (identifiedInitiatives?.length) {
        firstThresholdIndex = Math.floor(identifiedInitiatives.length * firstThreshold / 100);
        secThresholdIndex = Math.floor(identifiedInitiatives.length * secThreshold / 100);
        maxIndex = identifiedInitiatives.length;
    } else {
        firstThresholdIndex = 0;
        secThresholdIndex = 0;
        maxIndex = 0;
    }

    return (
        <AdvisorContainer
            maxWidth="xl"
            title={`${projectName} – ${config.i18n.procurement.prioritize.title}`}
            actions={(
                <>
                    <FormControlLabel
                        control={(
                            <Switch
                                checked={isFitted}
                                onChange={(event) => {
                                    setIsFitted(event.target.checked);
                                }}
                            />
                        )}
                        label={config.i18n.procurement.prioritize.show_fitted}
                        sx={{ mr: 4 }}
                    />
                    <Button
                        variant="contained"
                        startIcon={<FormatListBulletedIcon />}
                        onClick={() => setOpenIdentify(true)}
                        title={config.i18n.procurement.prioritize.initiatives}
                    >
                        {config.i18n.procurement.prioritize.initiatives}
                    </Button>
                </>
            )}
        >
            <Wizard
                steps={steps}
                currentStep="prioritize"
                stepValidation={projectStatus}
                hasChanges={hasChanges}
                // force save button active if we never saved the prioritization.
                forceEnableSave={projectStep === "START"}
                basePath={basePath}
                originPath={originPath}
                disableStepper
                onSave={() => {
                    if (!isValid()) {
                        return;
                    }

                    setSaving(true);
                    // save all initiatives, wait and then call prioritize.
                    client.procurement.procurementBulkUpdateProjectInitiative(projectId, Object.values(initiativesToSave))
                        .then(() => {
                            const payload = initiatives.map(initiative => (
                                {
                                    id: initiative.id,
                                    identified: initiative.identified,
                                    prioritized: initiative.prioritized,
                                }
                            ));

                            client.procurement.procurementPrioritizeInitiatives(projectId, payload)
                                .then((project) => {
                                    setInitiatives(project.initiatives);
                                    setProjectStep(project.step);
                                    setHasChanges(false);
                                    setSaving(false);
                                    setInitiativesToSave({});

                                    validateProject(client, notify, projectId, validateAndSetStatus);
                                })
                                .catch((error) => {
                                    setSaving(false);
                                    notify.error(error, "procurement.project.prioritization");
                                });
                        })
                        .catch((error) => {
                            setSaving(false);
                            notify.error(error, "procurement.project.initiative.update");
                        });
                }}

                onDiscard={() => setHasChanges(false)}
            >
                <FullscreenLoading
                    loading={saving}
                    label={config.i18n.procurement.prioritize.saving}
                />
                <FullscreenLoading
                    loading={previewing}
                    label={config.i18n.procurement.prioritize.previewing}
                />
                <InitiativesDialog
                    open={openIdentify}
                    onClose={(event, reason) => {
                        if (reason && reason == "backdropClick") {
                            return;
                        }
                        setOpenIdentify(false);
                    }}
                    initiatives={initiatives}
                    onSave={(identifiedInitiatives) => {
                        initiatives.forEach((initiative) => {
                            initiative.identified = identifiedInitiatives.has(initiative.id);
                        });

                        setInitiatives([...initiatives]);
                        setHasChanges(true);
                    }}
                />
                <InitiativeDetailsDialog
                    initiative={initiativeToEdit}
                    onClose={(event, reason) => {
                        if (reason && reason == "backdropClick") {
                            return;
                        }
                        setInitiativeToEdit(null);
                    }}
                    onSave={(initiative) => {
                        const newInitiatives = [...initiatives];
                        const index = newInitiatives.findIndex(i => i.id === initiative.id);
                        newInitiatives[index] = initiative;
                        setInitiatives(newInitiatives);
                        setInitiativesToSave({ ...initiativesToSave, [initiative.id]: initiative });
                        setHasChanges(true);
                        preview(newInitiatives);
                    }}
                />
                <Grid container spacing={1}>
                    <EmptyCell size="small" />
                    <ScaleYCell>
                        {`${secThreshold}% - 100%`}
                    </ScaleYCell>
                    {
                        colors[0].map((color, index) =>
                            (
                                <ColoredCell
                                    key={"cell" + color}
                                    colorString={color}
                                    borderColorString={borderColors[0][index]}
                                >
                                    {getInitiativeItem(identifiedInitiatives, xxMetricFilter[index],
                                        secThresholdIndex, maxIndex)}
                                </ColoredCell>
                            ),
                        )
                    }
                    <LabelCell
                        size="small"
                        anchor={anchorY}
                        setAnchor={setAnchorY}
                        label={getYyMetricLabel(yyMetric, isFitted)}
                    >

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <SelectBox
                                labelId="yy-axis-select"
                                id="yy-select"
                                value={yyMetric}
                                size="small"
                                onChange={e => setYYMetric(e.target.value)}
                            >
                                {
                                    yyMetricOptions.map(option =>
                                        (
                                            <MenuItem key={"yyMetricOption-" + option} value={option}>
                                                {getYyMetricLabel(option, isFitted)}
                                            </MenuItem>
                                        ))
                                }
                            </SelectBox>
                        </FormControl>
                        <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
                            <Typography>0%</Typography>
                            <Slider
                                value={[firstThreshold, secThreshold]}
                                onChange={(event, newValue) => {
                                    setFirstThreshold(newValue[0]);
                                    setSecThreshold(newValue[1]);
                                }}
                                valueLabelDisplay="on"
                                valueLabelFormat={value => `${value}%`}
                                sx={{
                                    "width": 250,
                                    "mx": 1,
                                    ".MuiSlider-valueLabel": {
                                        background: "transparent",
                                    },
                                    ".MuiSlider-valueLabelLabel": {
                                        color: "text.primary",
                                    },
                                }}
                            />
                            <Typography>100%</Typography>
                        </Box>
                    </LabelCell>
                    <ScaleYCell>
                        {`${firstThreshold}% - ${secThreshold}%`}
                    </ScaleYCell>
                    {
                        colors[1].map((color, index) =>
                            (
                                <ColoredCell
                                    key={"cell" + color}
                                    colorString={color}
                                    borderColorString={borderColors[1][index]}
                                >
                                    {getInitiativeItem(identifiedInitiatives, xxMetricFilter[index],
                                        firstThresholdIndex, secThresholdIndex)}
                                </ColoredCell>
                            ),
                        )
                    }
                    <EmptyCell size="small" />
                    <ScaleYCell>
                        {`0% - ${firstThreshold}%`}
                    </ScaleYCell>
                    {
                        colors[2].map((color, index) =>
                            (
                                <ColoredCell
                                    key={"cell" + color}
                                    colorString={color}
                                    borderColorString={borderColors[2][index]}
                                >
                                    {getInitiativeItem(identifiedInitiatives, xxMetricFilter[index],
                                        0, firstThresholdIndex)}
                                </ColoredCell>
                            ),
                        )
                    }
                    <EmptyCell size="small" />
                    <EmptyCell size="small" />
                    {
                        [0, 1, 2].map(index =>
                            (
                                <ScaleXCell key={"xx-category-" + index}>
                                    {config.i18n.procurement[xxMetric].values[xxMetricFilter[index]]}
                                </ScaleXCell>
                            ),
                        )
                    }
                    <EmptyCell size="small" />
                    <EmptyCell size="small" />
                    <EmptyCell />
                    <LabelCell anchor={anchorX} setAnchor={setAnchorX} label={config.i18n.procurement[xxMetric].label}>
                        <FormControl fullWidth>
                            <SelectBox
                                labelId="xx-axis-select"
                                id="xx-select"
                                value={xxMetric}
                                size="small"
                                onChange={e => setXXMetric(e.target.value)}
                            >
                                {
                                    xxMetricOptions.map(option =>
                                        (
                                            <MenuItem key={"xxMetricOption-" + option} value={option}>
                                                {config.i18n.procurement[option].label}
                                            </MenuItem>
                                        ))
                                }
                            </SelectBox>
                        </FormControl>
                    </LabelCell>
                    <EmptyCell />
                </Grid>
            </Wizard>
        </AdvisorContainer>
    );
};

Prioritize.propTypes = {
    steps: PropTypes.array,
    basePath: PropTypes.string,
    originPath: PropTypes.string,
};

export default Prioritize;
