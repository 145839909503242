import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    Box,
    CircularProgress,
    Drawer,
    Fab,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
    useTheme,
} from "@mui/material";
import { BooleanParam, JsonParam, StringParam, useQueryParam } from "use-query-params";
import {
    formatAccountingDecimal,
    formatCompactAmount,
    formatDecimal,
    formatRatioToPercentage,
    formatTemplate,
    formatYearMonthShort,
    getAllMonthsBetweenDates,
    getCompactFormatter,
    getCurrencySymbol,
    getSymbol,
} from "../../util/formatter";
import AdvisorContainer from "../../layout/AdvisorContainer";
import dayjs from "dayjs";
import { Stack, styled } from "@mui/system";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadReport } from "../../util/report_util";
import { convertToISODate, hasValue } from "../../util/util";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { getFormattedValue } from "../../components/chart/common";
import ReportCustomizationBar from "./ReportCustomizationBar";
import { isEmpty, isEqual } from "lodash";
import { isForecastingEnabled } from "../../util/feature_flag_util";
import { DayJSParam } from "../../util/url";
import { AppContext } from "../../AppRouter";

const SubHeaderTableCell = styled(TableCell)(({ colSpan, theme }) => ({
    colSpan: colSpan,
    fontSize: "14px",
    textAlign: "center",
    whiteSpace: "nowrap",
    backgroundColor: theme.palette.grey["700"],
    color: "white",
    // Styling to stick subheader just below the header
    position: "sticky",
    top: "37px",
    zIndex: 1,
}));

const HeaderTableCell = styled(SubHeaderTableCell)(() => ({
    fontSize: "16px",
    borderRadius: "8px 8px 0 0",
    // Styling to stick header to the top of the table
    position: "sticky",
    top: 0,
    zIndex: 1,
}));

const IndexTableCell = styled(TableCell)(() => ({
    background: "white",
    // Styling to stick column to the left of the table
    position: "sticky",
    left: 0,
    zIndex: 1,
}));

const StyledTableCell = styled(TableCell)(({ fontStyle, color }) => ({
    fontSize: "13px",
    fontStyle: fontStyle,
    fontWeight: fontStyle,
    textAlign: "right",
    whiteSpace: "nowrap",
    backgroundColor: color || "inherit",
}));

const EmptyTableCell = styled(TableCell)(size => ({
    colSpan: size || "1",
    borderBottom: "none",
}));

const ReadReport = () => {
    const { client, config, notify, user } = useContext(AppContext);
    const theme = useTheme();

    const { reportId } = useParams();

    const [hasReference, setHasReference] = useState(true);

    const [multiplier, setMultiplier] = useQueryParam("multiplier", StringParam, { enableBatching: true });
    const multiplierOptions = config.i18n.report.multiplier.options;

    const [hideInitialMonths, setHideInitialMonths] = useQueryParam("hideInitialMonths", BooleanParam, { enableBatching: true });
    const [hideEmptyRows, setHideEmptyRows] = useQueryParam("hideRows", BooleanParam, { enableBatching: true });
    const [reportResults, setReportResults] = useState(null);
    const [computingReport, setComputingReport] = useState(false);
    const [report, setReport] = useState();
    const [description, setDescription] = useState();

    const [scenarioOptions, setScenarioOptions] = useState();
    const [scenario, setScenario] = useQueryParam("scenario", JsonParam, { enableBatching: true });
    const [documentDimensions, setDocumentDimensions] = useState(null);
    const [reportDimensions, setReportDimensions] = useQueryParam("dims", JsonParam, { enableBatching: true });
    const [reportDimensionFilters, setReportDimensionFilters] = useQueryParam("dimParams", JsonParam, { enableBatching: true });
    const [reportDimensionsMetadata, setReportDimensionsMetadata] = useState(null);

    const historicalMin = dayjs(config.time.historical.min);
    const historicalMax = dayjs(config.time.historical.max);
    const [maxDate, setMaxDate] = useState(null);
    const [startDate, setStartDate] = useQueryParam("startDate", DayJSParam, { enableBatching: true });
    const [endDate, setEndDate] = useQueryParam("endDate", DayJSParam, { enableBatching: true });

    const [open, setOpen] = useState();
    const [visibility, setVisibility] = useState();

    const [loadingDownload, setLoadingDownload] = useState(false);

    const [isCustomizationDrawerOpen, setIsCustomizationDrawerOpen] = useState(true);
    const drawerWidth = 388;
    const renderDrawer = startDate && endDate && documentDimensions && reportDimensions && reportDimensionFilters && reportDimensionsMetadata
        && (!isForecastingEnabled(config) || scenario);

    const issueDownloadReport = (title, reportId) => {
        setLoadingDownload(true);
        downloadReport(title, reportId, {
            scenario_id: scenario?.id,
            start_date: convertToISODate(startDate),
            end_date: convertToISODate(endDate),
            ui_filters: {
                dimensions: reportDimensions,
                dimension_filters: reportDimensionFilters,
            },
        },
        () => setLoadingDownload(false),
        () => {
            setLoadingDownload(false);
            notify.error(config.i18n.error.download.error);
        });
    };

    const hideRow = (row, index) => {
        return hideEmptyRows && isRowEmpty(row) && areChildrenRowsEmpty(row, index);
    };

    const isRowEmpty = (row) => {
        return Object.values(row.actual.dates).every(val => !val) && !row.actual.ytd && !row.actual.fy
            && Object.values(row.reference?.dates).every(val => !val) && !row.reference?.ytd && !row.reference?.fy
            && Object.values(row.ar_diff?.dates).every(val => !val) && !row.ar_diff?.ytd && !row.ar_diff?.fy
            && Object.values(row.ar_div?.dates).every(val => !val) && !row.ar_div?.ytd && !row.ar_div?.fy;
    };

    const areChildrenRowsEmpty = (row, index) => {
        if (isParentRow(index, row.indentation)) {
            let curIndentation = row.indentation + 1;
            while (++index < reportResults.results.length && curIndentation > row.indentation) {
                if (!isRowEmpty(reportResults.results[index])) {
                    return false;
                } else {
                    curIndentation = reportResults.results[index].indentation;
                }
            }
        }

        return true;
    };

    const showColumn = (index) => {
        return !hideInitialMonths || index === dateColumnsActual.length - 1;
    };

    const toggleOpen = (index) => {
        const newOpen = [...open];
        const newVisibility = [...visibility];

        newOpen[index] = !newOpen[index];

        for (let i = index + 1; i <= reportResults.results.length - 1; i++) {
            if (reportResults.results[i].indentation <= reportResults.results[index].indentation) {
                break;
            }

            if (newOpen[index] !== newOpen[i]) {
                newOpen[i] = !newOpen[i];
            }
            newVisibility[i] = newOpen[i];
        }

        setOpen(newOpen);
        setVisibility(newVisibility);
    };

    const setIntersectingDocumentDimensions = (reportTemplateId) => {
        client.report.reportGetReportTemplateIntersectingDimensions(reportTemplateId)
            .then(dimensions => setDocumentDimensions(dimensions))
            .catch(error => notify.error(error, "report.fetch"));
    };

    const fetchMetadata = (dimensionFilters) => {
        const ids = Object.values(dimensionFilters).flat();
        client.dimension.dimensionMetadata(ids)
            .then(metadata => setReportDimensionsMetadata(metadata))
            .catch(error => notify.error(error, "report.fetch"));
    };

    const populateDimensionSelector = (reportTemplateId, dimensions, dimensionFilters, urlOption) => {
        setIntersectingDocumentDimensions(reportTemplateId);

        // only populate dimensions coming from report, if the URL doesn't come with any.
        if (isEmpty(reportDimensions)) {
            setReportDimensions(dimensions, urlOption);
        }
        if (isEmpty(reportDimensionFilters)) {
            setReportDimensionFilters(dimensionFilters, urlOption);
        }

        fetchMetadata({
            ...dimensionFilters,
            ...reportDimensionFilters,
        });
    };

    const fetchReport = (reportId, scenarioOptions = []) => {
        client.report.reportGetReport(reportId)
            .then((report) => {
                setMaxDate(isForecastingEnabled(config) && report.scenario_id ? dayjs(config.time.forecasting.max) : historicalMax);

                // replace URL to avoid pressing back and go through a state where there isn't parameters.
                const urlOption = "replaceIn";

                if (isEmpty(scenario)) {
                    setScenario(scenarioOptions?.find((el => el.id === report.scenario_id)) || {}, urlOption);
                }

                if (isEmpty(startDate)) {
                    setStartDate(dayjs(report.start_date), urlOption);
                }

                if (isEmpty(endDate)) {
                    setEndDate(dayjs(report.end_date), urlOption);
                }

                if (isEmpty(multiplier)) {
                    setMultiplier(report.multiplier, urlOption);
                }

                if (hideEmptyRows == null) {
                    setHideEmptyRows(report.hide_empty_rows, urlOption);
                }

                if (hideInitialMonths == null) {
                    setHideInitialMonths(report.hide_initial_months, urlOption);
                }

                populateDimensionSelector(report.report_template_id, report.ui_filters.dimensions, report.ui_filters.dimension_filters, urlOption);

                fetchDescription(report.report_template_id);

                // setting the report ensures report calculation is only done, after getting the report first.
                setReport(report);
            })
            .catch((error) => {
                notify.error(error, "report.get");
            });
    };

    const fetchDescription = (reportTemplateId) => {
        client.report.reportGetReportTemplateDescription(reportTemplateId)
            .then((description) => {
                setDescription(description);
            })
            .catch((error) => {
                setDescription("");
                notify.error(error, "report.fetch");
            });
    };

    useEffect(() => {
        if (reportId) {
            // ensuring scenario matters only if FF is enabled
            if (isForecastingEnabled(config) && user.permissions?.FORECAST?.READ) {
                client.scenario.scenarioListScenarios()
                    .then((scenarios) => {
                        setScenarioOptions(scenarios.response);
                        fetchReport(reportId, scenarios.response);
                    })
                    .catch((error) => {
                        setScenarioOptions(null);
                        notify.error(error, "scenario.fetch");
                    });
            } else {
                fetchReport(reportId);
            }
        }
    }, [reportId]);

    useEffect(() => {
        // ensuring scenario matters only if FF is enabled.
        // report is important, to make sure we only compute after fetching the report first.
        // using the URL, all other parameters may be present first, without the report itself.
        if (reportId && report && startDate && endDate && reportDimensions && reportDimensionFilters && (!isForecastingEnabled(config) || scenario)) {
            setComputingReport(true);
            client.report.reportComputeReport(reportId, {
                scenario_id: scenario?.id,
                start_date: convertToISODate(startDate),
                end_date: convertToISODate(endDate),
                ui_filters: {
                    dimensions: reportDimensions,
                    dimension_filters: reportDimensionFilters,
                },
            })
                .then((reportResults) => {
                    setReportResults(reportResults);
                    setOpen(Array(reportResults.results.length).fill(true));
                    setVisibility(Array(reportResults.results.length).fill(true));
                    setHasReference(reportResults.results.some(el => el.reference));
                    setComputingReport(false);
                })
                .catch((error) => {
                    setComputingReport(false);
                    notify.error(error, "report.compute");
                });
        }
    }, [reportId, report, startDate, endDate, scenario, reportDimensionFilters]);

    const hasForecasting = () => {
        return isForecastingEnabled(config) && dateColumnsActual.some(el => el >= config.time.forecasting.min);
    };

    const isParentRow = (index, indentation) => {
        return reportResults.results[index + 1]?.indentation > 0 && indentation < reportResults.results[index + 1]?.indentation;
    };

    const getAllValuesByType = (types, block, index = -1, independent = false) => {
        const values = [0];

        if (reportResults) {
            for (let i = 0; i < reportResults.results?.length; i++) {
                if ((!independent && !reportResults.results[i].independent) || (independent && i === index)) {
                    if ((types.includes(reportResults.results[i]?.label_type_actual.y)) && (block === "actual" || block === "reference")) {
                        values.push(...Object.values(reportResults.results[i].actual.dates).filter(val => hasValue(val)));
                        values.push(...Object.values(reportResults.results[i]?.reference.dates).filter(val => hasValue(val)));
                    }

                    if ((types.includes(reportResults.results[i]?.label_type_ar_diff.y)) && block === "ar_diff") {
                        values.push(...Object.values(reportResults.results[i]?.ar_diff.dates).filter(val => hasValue(val)));
                        values.push(reportResults.results[i]?.ar_diff.ytd || 0);
                        values.push(reportResults.results[i]?.ar_diff.fy || 0);
                    }

                    if ((types.includes(reportResults.results[i]?.label_type_ar_div.y)) && block === "ar_div") {
                        values.push(...Object.values(reportResults.results[i]?.ar_div.dates).filter(val => hasValue(val)));
                        values.push(reportResults.results[i]?.ar_div.ytd || 0);
                        values.push(reportResults.results[i]?.ar_div.fy || 0);
                    }
                }
            }
        }

        return values;
    };

    const getFormattedCellValue = (row, index, val, labelType, block) => {
        if (!hasValue(val)) {
            return "-";
        }

        let formatter;
        switch (labelType.y) {
            case "PERCENTAGE":
                formatter = formatRatioToPercentage.bind(null, val, config.locale, true, 0);
                break;
            case "AMOUNT":
                formatter = getCompactFormatter(
                    getAllValuesByType(["AMOUNT"], block, index, row.independent),
                    config.locale,
                    config.i18n,
                    false,
                    row.independent ? formatCompactAmount : formatAccountingDecimal,
                    !row.independent,
                    row.independent,
                    row.independent ? "AUTO" : multiplier);
                break;
            default:
                formatter = getCompactFormatter(
                    getAllValuesByType([labelType.y], block, index, row.independent),
                    config.locale,
                    config.i18n,
                    false,
                    formatDecimal);
                break;
        }

        return formatter(val);
    };

    if (!report) {
        return null;
    }

    const title = report.name;

    const subtitle = reportResults && reportResults.subtitle && formatTemplate(
        reportResults.subtitle,
        reportResults.metadata,
        config.locale,
        config.i18n);

    const otherColumns = ["YTD", "FY"];
    const dateColumnsActual = getAllMonthsBetweenDates(startDate, endDate);

    let dateColumnsReference = dateColumnsActual;
    if (reportResults) {
        const offset = reportResults.results[0].offset;
        const start = dayjs(startDate).add(offset, "month");
        const end = dayjs(endDate).add(offset, "month");

        dateColumnsReference = getAllMonthsBetweenDates(start, end);
    }

    return (
        <>
            <AdvisorContainer
                title={title}
                toolTipInfo={description}
                subtitle={subtitle}
                actions={(
                    <Fab
                        aria-label="download"
                        onClick={() => issueDownloadReport(report.name, reportId)}
                    >
                        {loadingDownload
                            ? <CircularProgress size={10} />
                            : <DownloadIcon sx={{ fontSize: "30px", margin: "auto" }} />}
                    </Fab>
                )}
                loading={!reportResults || computingReport || !renderDrawer}
                loadingLabel={config.i18n.report.read.loading}
                minWidth={0}
                maxWidth="xxl"
                maxHeight="calc(100vh - 200px)" // Ensure the height of this page is the height of the screen
            >
                {reportResults
                    ? (
                        <Box marginTop={1} maxHeight="inherit">
                            <TableContainer sx={{ overflow: "auto", maxHeight: "inherit" }}>
                                <Table size="small" style={{ borderCollapse: "separate" }} data-cy="report">
                                    <TableHead>
                                        <TableRow style={{ height: "32px" }}>
                                            <TableCell
                                                colSpan={1}
                                                rowSpan={2}
                                                style={{
                                                // Cell has custom styling since it needs to stick to the top left corner of the table
                                                    backgroundColor: "white",
                                                    position: "sticky",
                                                    top: 0,
                                                    left: 0,
                                                    zIndex: 2,
                                                    fontSize: "16px",
                                                    textAlign: "center",
                                                    fontStyle: "italic",
                                                }}
                                            >
                                                {getCurrencySymbol(config.locale) + " "
                                                + getSymbol(getAllValuesByType(["AMOUNT"], "actual"), config.locale, multiplier)}
                                            </TableCell>
                                            <HeaderTableCell
                                                colSpan={hideInitialMonths ? 3 : dateColumnsActual.length + otherColumns.length}
                                            >
                                                {hasForecasting() ? config.i18n.report.read.actual_forecasting : config.i18n.report.read.actual}
                                            </HeaderTableCell>
                                            <EmptyTableCell />
                                            { hasReference
                                                ? (
                                                    <>
                                                        <HeaderTableCell
                                                            colSpan={hideInitialMonths ? 3 : dateColumnsActual.length + otherColumns.length}
                                                        >
                                                            {config.i18n.report.read.ar_diff}
                                                        </HeaderTableCell>
                                                        <EmptyTableCell />
                                                        <HeaderTableCell
                                                            colSpan={hideInitialMonths ? 3 : dateColumnsActual.length + otherColumns.length}
                                                        >
                                                            {config.i18n.report.read.ar_div}
                                                        </HeaderTableCell>
                                                        <EmptyTableCell />
                                                        <HeaderTableCell
                                                            colSpan={hideInitialMonths ? 3 : dateColumnsActual.length + otherColumns.length}
                                                        >
                                                            {hasForecasting() ? config.i18n.report.read.reference_forecasting : config.i18n.report.read.reference}
                                                        </HeaderTableCell>
                                                    </>
                                                    )
                                                : null}
                                        </TableRow>
                                        <TableRow style={{ height: "32px" }}>
                                             {dateColumnsActual.map((key, colIndex) => (
                                                 showColumn(colIndex) ? (
                                                     <SubHeaderTableCell key={key}>
                                                         {formatYearMonthShort(key)}
                                                     </SubHeaderTableCell>
                                                 ) : null
                                             ))}
                                            {otherColumns.map(key => (
                                                <SubHeaderTableCell key={key}>
                                                    {key}
                                                </SubHeaderTableCell>
                                            ))}
                                            <EmptyTableCell />
                                            { hasReference
                                                ? (
                                                    <>
                                                        {dateColumnsActual.map((key, colIndex) => (
                                                            showColumn(colIndex) ? (
                                                                <SubHeaderTableCell key={key}>
                                                                    {formatYearMonthShort(key)}
                                                                </SubHeaderTableCell>
                                                            ) : null
                                                        ))}
                                                        {otherColumns.map(key => (
                                                            <SubHeaderTableCell key={key}>
                                                                {key}
                                                            </SubHeaderTableCell>
                                                        ))}
                                                        <EmptyTableCell />
                                                        {dateColumnsActual.map((key, colIndex) => (
                                                            showColumn(colIndex) ? (
                                                                <SubHeaderTableCell key={key}>
                                                                    {formatYearMonthShort(key)}
                                                                </SubHeaderTableCell>
                                                            ) : null
                                                        ))}
                                                        {otherColumns.map(key => (
                                                            <SubHeaderTableCell key={key}>
                                                                {key}
                                                            </SubHeaderTableCell>
                                                        ))}
                                                        <EmptyTableCell />
                                                        {dateColumnsReference.map((key, colIndex) => (
                                                            showColumn(colIndex) ? (
                                                                <SubHeaderTableCell key={key}>
                                                                    {formatYearMonthShort(key)}
                                                                </SubHeaderTableCell>
                                                            ) : null
                                                        ))}
                                                        {otherColumns.map(key => (
                                                            <SubHeaderTableCell key={key}>
                                                                {key}
                                                            </SubHeaderTableCell>
                                                        ))}
                                                    </>
                                                    )
                                                : null}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportResults.results.map((row, index) => (
                                            <TableRow
                                                key={row.name}
                                                style={{ backgroundColor: "inherit",
                                                    height: "40px",
                                                    visibility: (!visibility[index] && row.indentation > 0) || hideRow(row, index) ? "collapse" : "visible" }}
                                            >
                                                <IndexTableCell>
                                                    <Stack direction="row" alignItems="center" gap={2} marginLeft={row.indentation * 3}>
                                                        <Box width={16} marginLeft={-2}>
                                                            {isParentRow(index, reportResults.results[index]?.indentation)
                                                            && (!hideEmptyRows || !areChildrenRowsEmpty(row, index))
                                                            && (
                                                                <IconButton size="small" onClick={() => toggleOpen(index)}>
                                                                    {!open[index]
                                                                        ? <KeyboardArrowRightIcon sx={{ fontSize: "1rem" }} />
                                                                        : <KeyboardArrowDownIcon sx={{ fontSize: "1rem" }} />}
                                                                </IconButton>
                                                            )}
                                                        </Box>
                                                        <Typography
                                                            style={{
                                                                fontStyle: row.text_style,
                                                                fontWeight: row.text_style,
                                                                whiteSpace: "nowrap",
                                                                display: "flex",
                                                                alignItems: "right",
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            {row.name}
                                                        </Typography>
                                                    </Stack>
                                                </IndexTableCell>
                                                {dateColumnsActual.map((key, colIndex) => (
                                                    showColumn(colIndex) ? (
                                                        <StyledTableCell
                                                            title={getFormattedValue(row.actual.dates[key], "y", row, config, row.label_type_actual, false, true, true)}
                                                            key={key}
                                                            fontStyle={row.text_style}
                                                            color={hasForecasting() && key < config.time.forecasting.min ? theme.palette.grey["200"] : "inherit"}
                                                        >
                                                            {getFormattedCellValue(row, index, row.actual.dates[key], row.label_type_actual, "actual")}
                                                        </StyledTableCell>
                                                    ) : null
                                                ))}
                                                <StyledTableCell
                                                    title={getFormattedValue(row.actual.ytd, "y", row, config, row.label_type_actual, false, true, true)}
                                                    fontStyle={row.text_style}
                                                >
                                                    {getFormattedCellValue(row, index, row.actual.ytd, row.label_type_actual, "actual")}
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    title={getFormattedValue(row.actual.fy, "y", row, config, row.label_type_actual, false, true, true)}
                                                    fontStyle={row.text_style}
                                                >
                                                    {getFormattedCellValue(row, index, row.actual.fy, row.label_type_actual, "actual")}
                                                </StyledTableCell>
                                                { hasReference
                                                    ? (
                                                        <>
                                                            <EmptyTableCell />
                                                            {dateColumnsActual.map((key, colIndex) => (
                                                                showColumn(colIndex) ? (
                                                                    <StyledTableCell
                                                                        title={getFormattedValue(row.ar_diff?.dates[key], "y", row, config, row.label_type_ar_diff,
                                                                            false, true, true)}
                                                                        key={key}
                                                                        fontStyle={row.text_style}
                                                                        color={hasForecasting() && key < config.time.forecasting.min ? theme.palette.grey["200"] : "inherit"}
                                                                    >
                                                                        {getFormattedCellValue(row, index, row.ar_diff?.dates[key], row.label_type_ar_diff, "ar_diff")}
                                                                    </StyledTableCell>
                                                                ) : null
                                                            ))}
                                                            <StyledTableCell
                                                                title={getFormattedValue(row.ar_diff?.ytd, "y", row, config, row.label_type_ar_diff, false, true, true)}
                                                                fontStyle={row.text_style}
                                                            >
                                                                {getFormattedCellValue(row, index, row.ar_diff?.ytd, row.label_type_ar_diff, "ar_diff")}
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                title={getFormattedValue(row.ar_diff?.fy, "y", row, config, row.label_type_ar_diff, false, true, true)}
                                                                fontStyle={row.text_style}
                                                            >
                                                                {getFormattedCellValue(row, index, row.ar_diff?.fy, row.label_type_ar_diff, "ar_diff")}
                                                            </StyledTableCell>
                                                            <EmptyTableCell />
                                                            {dateColumnsActual.map((key, colIndex) => (
                                                                showColumn(colIndex) ? (
                                                                    <StyledTableCell
                                                                        title={getFormattedValue(row.ar_div?.dates[key], "y", row, config, row.label_type_ar_div,
                                                                            false, true, true)}
                                                                        key={key}
                                                                        fontStyle={row.text_style}
                                                                        color={hasForecasting() && key < config.time.forecasting.min ? theme.palette.grey["200"] : "inherit"}
                                                                    >
                                                                        {getFormattedCellValue(row, index, row.ar_div?.dates[key], row.label_type_ar_div, "ar_div")}
                                                                    </StyledTableCell>
                                                                ) : null
                                                            ))}
                                                            <StyledTableCell
                                                                title={getFormattedValue(row.ar_div?.ytd, "y", row, config, row.label_type_ar_div, false, true, true)}
                                                                fontStyle={row.text_style}
                                                            >
                                                                {getFormattedCellValue(row, index, row.ar_div?.ytd, row.label_type_ar_div, "ar_div")}
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                title={getFormattedValue(row.ar_div?.fy, "y", row, config, row.label_type_ar_div, false, true, true)}
                                                                fontStyle={row.text_style}
                                                            >
                                                                {getFormattedCellValue(row, index, row.ar_div?.fy, row.label_type_ar_div, "ar_div")}
                                                            </StyledTableCell>
                                                            <EmptyTableCell />
                                                            {dateColumnsReference.map((key, colIndex) => (
                                                                showColumn(colIndex) ? (
                                                                    <StyledTableCell
                                                                        title={getFormattedValue(row.reference.dates[key], "y", row, config, row.label_type_reference,
                                                                            false, true, true)}
                                                                        key={key}
                                                                        fontStyle={row.text_style}
                                                                        color={hasForecasting() && key < config.time.forecasting.min ? theme.palette.grey["200"] : "inherit"}
                                                                    >
                                                                        {getFormattedCellValue(row, index, row.reference.dates[key], row.label_type_reference, "reference")}
                                                                    </StyledTableCell>
                                                                ) : null
                                                            ))}
                                                            <StyledTableCell
                                                                title={getFormattedValue(row.reference?.ytd, "y", row, config, row.label_type_reference,
                                                                    false, true, true)}
                                                                fontStyle={row.text_style}
                                                            >
                                                                {getFormattedCellValue(row, index, row.reference?.ytd, row.label_type_reference, "reference")}
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                title={getFormattedValue(row.reference?.fy, "y", row, config, row.label_type_reference,
                                                                    false, true, true)}
                                                                fontStyle={row.text_style}
                                                            >
                                                                {getFormattedCellValue(row, index, row.reference?.fy, row.label_type_reference, "reference")}
                                                            </StyledTableCell>
                                                        </>
                                                        )
                                                    : null}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        ) : null}
            </AdvisorContainer>
            { renderDrawer
                ? (
                    <Drawer
                        variant="persistent"
                        anchor="right"
                        open={isCustomizationDrawerOpen}
                        sx={{
                            "width": isCustomizationDrawerOpen ? drawerWidth : 0,
                            "flexShrink": 0,
                            "& .MuiDrawer-paper": {
                                width: drawerWidth,
                                boxSizing: "border-box",
                            },
                        }}
                    >
                        <Toolbar />
                        <IconButton
                            onClick={() => setIsCustomizationDrawerOpen(!isCustomizationDrawerOpen)}
                            sx={{ mr: 1, mb: -2, alignSelf: "end" }}
                        >
                            <KeyboardDoubleArrowRightIcon />
                        </IconButton>
                        <ReportCustomizationBar
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            globalMin={historicalMin}
                            globalMax={historicalMax}
                            maxDate={maxDate}
                            setMaxDate={setMaxDate}
                            scenario={scenario}
                            setScenario={setScenario}
                            scenarioOptions={scenarioOptions}
                            multiplier={multiplier}
                            setMultiplier={setMultiplier}
                            multiplierOptions={multiplierOptions}
                            hideRows={hideEmptyRows}
                            setHideRows={setHideEmptyRows}
                            hideInitialMonths={hideInitialMonths}
                            setHideInitialMonths={setHideInitialMonths}
                            documentDimensions={documentDimensions}
                            reportDimensions={reportDimensions}
                            reportDimensionFilters={reportDimensionFilters}
                            reportDimensionsMetadata={reportDimensionsMetadata}
                            onDimensionUpdate={(dimensions, dimensionFilters, metadata = null) => {
                                // only set dimensionFilters if it isn't equal to previous.
                                if (!isEqual(reportDimensionFilters, dimensionFilters)) {
                                    setReportDimensionFilters(dimensionFilters);
                                }

                                setReportDimensions(dimensions);
                                setReportDimensionsMetadata({
                                    ...reportDimensionsMetadata,
                                    ...metadata,
                                });
                            }}
                            disabled={!reportResults || computingReport}
                        />
                    </Drawer>
                    ) : null}
            { !isCustomizationDrawerOpen
                ? (
                    <Box position="absolute" top={0} right={0}>
                        <Toolbar />
                        <IconButton
                            onClick={() => setIsCustomizationDrawerOpen(!isCustomizationDrawerOpen)}
                            sx={{ alignSelf: "start" }}
                        >
                            <KeyboardDoubleArrowLeftIcon />
                        </IconButton>
                    </Box>
                    )
                : null}
        </>
    );
};

export default ReadReport;
