import React, { createContext, useEffect, useState } from "react";
import theme from "./theme/theme";
import { useSnackbar } from "notistack";
import { CssBaseline, ThemeProvider } from "@mui/material";
import {
    AnalysisService,
    AuthenticationService,
    CollectorService,
    ConfigurationService,
    DimensionService,
    ProcurementService,
    QueryService,
    ReportService,
    ScenarioService,
    TryThisService,
    UserService,
} from "./client";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Helmet } from "react-helmet";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import * as dayjsLocalePtPT from "dayjs/locale/pt";
import { Requires } from "./util/Requires";
import Layout from "./layout/Layout";
import AskTry from "./sections/AskTry";
import Ask from "./sections/Ask";
import Explore from "./sections/Explore";
import Scenario from "./sections/scenario/Scenario";
import CreateScenario from "./sections/scenario/CreateScenario";
import CloneScenario from "./sections/scenario/CloneScenario";
import EditScenario from "./sections/scenario/EditScenario";
import Report from "./sections/report/Report";
import CloneReport from "./sections/report/CloneReport";
import CreateReport from "./sections/report/CreateReport";
import EditReport from "./sections/report/EditReport";
import ReadReport from "./sections/report/ReadReport";
import Collector from "./sections/collector/Collector";
import CollectorEntry from "./sections/collector/CollectorEntry";
import CollectorEntryItemsEdit from "./sections/collector/CollectorEntryItemsEdit";
import Procurement from "./sections/procurement/Procurement";
import ProjectDetails from "./sections/procurement/ProjectDetails";
import Prioritize from "./sections/procurement/wizard/prioritize/Prioritize";
import Schedule from "./sections/procurement/wizard/Schedule";
import { UserGuideAsk, UserGuideForecast, UserGuideOptimize, UserGuideReport } from "./sections/user_guide/UserGuide";
import ErrorContainer from "./layout/Error";
import CreateCollector from "./sections/collector/CreateCollector";
import EditCollector from "./sections/collector/EditCollector";
import CloneCollector from "./sections/collector/CloneCollector";

export const AppContext = createContext({});
export const AppRouter = () => {
    const [user, setUser] = useState();
    const [config, setConfig] = useState();
    const { enqueueSnackbar } = useSnackbar();

    const client = {
        auth: AuthenticationService,
        analysis: AnalysisService,
        collector: CollectorService,
        config: ConfigurationService,
        dimension: DimensionService,
        query: QueryService,
        procurement: ProcurementService,
        user: UserService,
        tryThis: TryThisService,
        scenario: ScenarioService,
        report: ReportService,
    };

    useEffect(() => {
        client.config.configurationGet()
            .then((response) => {
                setConfig(response);
            })
            .catch((error) => {
                setConfig(null);
                console.error("Error getting configuration.", error.body?.detail);
            });

        client.auth.authenticationGetProfile()
            .then((response) => {
                setUser(response);
            })
            .catch((error) => {
                setUser(null);
                console.error("Error getting user profile", error.body?.detail);
            });
    }, []);

    // global state store
    const store = {
        user: user,
        client: client,
        config: config,
        notify: {
            error: (error, i18nSnackbarKey) => {
                enqueueSnackbar(config.i18n.error[i18nSnackbarKey] || config.i18n.error["unexpected.error"], { variant: "error", preventDuplicate: true });
            },
            warn: (i18nSnackbarKey, ...extra) => {
                if (config.i18n.warn[i18nSnackbarKey]) {
                    enqueueSnackbar(config.i18n.warn[i18nSnackbarKey] + " " + extra, { variant: "warning", preventDuplicate: true });
                }
            },
            info: (i18nSnackbarKey) => {
                if (config.i18n.info[i18nSnackbarKey]) {
                    enqueueSnackbar(config.i18n.info[i18nSnackbarKey], { variant: "default", preventDuplicate: true });
                }
            },
            debug: (message) => {
                if (config.visual?.debug) {
                    enqueueSnackbar(message, { variant: "warning", preventDuplicate: true });
                }
            },
        },
    };

    // pick locale according configuration
    // cannot load again the default 'en' locale, so we are defaulting to it
    const dayjsLocale = config?.locale.language === "pt-PT" ? dayjsLocalePtPT : null;

    function getPathFromFeatureFlag(module) {
        switch (module) {
            case "ASK_ME":
                return "/ask";
            case "EXPLORE":
                return "/explore";
            case "FORECAST":
                return "/scenario";
            case "REPORT":
                return "/report";
            case "COLLECTOR":
                return "/collector";
            case "OPTIMIZE":
                return "/procurement";
            case "USER_GUIDE":
                return "/user-guide/ask";
            default:
                return "/ask";
        }
    }

    if (config) {
        return (
            <Router>
                <AppContext.Provider value={store}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjsLocale}>
                        <ThemeProvider theme={theme}>
                            <Helmet>
                                <title>{config?.i18n.page.main}</title>
                            </Helmet>
                            <CssBaseline />
                            <QueryParamProvider adapter={ReactRouter6Adapter}>
                                <Routes>
                                    <Route
                                        element={(
                                            <Requires flag="ASK_ME">
                                                <Layout>
                                                    <AskTry />
                                                </Layout>
                                            </Requires>
                                        )}
                                        path="/ask"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="ASK_ME">
                                                <Layout>
                                                    <Ask />
                                                </Layout>
                                            </Requires>
                                        )}
                                        path="/ask/:analysisId"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="EXPLORE">
                                                <Layout>
                                                    <Explore />
                                                </Layout>
                                            </Requires>
                                        )}
                                        path="/explore"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="FORECAST">
                                                <Layout>
                                                    <Scenario />
                                                </Layout>
                                            </Requires>
                                        )}
                                        path="/scenario"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="FORECAST">
                                                <Layout>
                                                    <CreateScenario />
                                                </Layout>
                                            </Requires>
                                        )}
                                        path="/scenario/create"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="FORECAST">
                                                <Layout>
                                                    <CloneScenario />
                                                </Layout>
                                            </Requires>
                                        )}
                                        path="/scenario/:scenarioId/clone"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="FORECAST">
                                                <Layout>
                                                    <EditScenario />
                                                </Layout>
                                            </Requires>
                                        )}
                                        path="/scenario/:scenarioId/edit"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="REPORT">
                                                <Layout>
                                                    <Report />
                                                </Layout>
                                            </Requires>
                                        )}
                                        path="/report"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="REPORT">
                                                <Layout>
                                                    <CloneReport />
                                                </Layout>
                                            </Requires>
                                        )}
                                        path="/report/:reportId/clone"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="REPORT">
                                                <Layout>
                                                    <CreateReport />
                                                </Layout>
                                            </Requires>
                                        )}
                                        path="/report/create"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="REPORT">
                                                <Layout>
                                                    <EditReport />
                                                </Layout>
                                            </Requires>
                                        )}
                                        path="/report/:reportId/edit"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="REPORT">
                                                <Layout>
                                                    <ReadReport />
                                                </Layout>
                                            </Requires>
                                        )}
                                        path="/report/:reportId"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="COLLECTOR">
                                                <Layout>
                                                    <Collector />
                                                </Layout>
                                            </Requires>
                                        )}
                                        path="/collector"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="COLLECTOR">
                                                <Layout>
                                                    <CreateCollector />
                                                </Layout>
                                            </Requires>
                                        )}
                                        path="/collector/create"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="COLLECTOR">
                                                <Layout>
                                                    <CollectorEntry />
                                                </Layout>
                                            </Requires>
                                        )}
                                        path="/collector/:collectorId"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="COLLECTOR">
                                                <Layout>
                                                    <CollectorEntryItemsEdit />
                                                </Layout>
                                            </Requires>
                                        )}
                                        path="/collector/:collectorId/:entryId"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="COLLECTOR">
                                                <Layout>
                                                    <EditCollector />
                                                </Layout>
                                            </Requires>
                                        )}
                                        path="/collector/:collectorId/edit"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="COLLECTOR">
                                                <Layout>
                                                    <CloneCollector />
                                                </Layout>
                                            </Requires>
                                        )}
                                        path="/collector/:collectorId/clone"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="OPTIMIZE">
                                                <Layout>
                                                    <Procurement />
                                                </Layout>
                                            </Requires>
                                        )}
                                        path="/procurement"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="OPTIMIZE">
                                                <Layout>
                                                    <ProjectDetails operation="create" />
                                                </Layout>
                                            </Requires>
                                        )}
                                        path="/procurement/project/create"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="OPTIMIZE">
                                                <Layout>
                                                    <ProjectDetails operation="edit" />
                                                </Layout>
                                            </Requires>
                                        )}
                                        path="/procurement/project/:projectId/edit"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="OPTIMIZE">
                                                <Layout>
                                                    <Prioritize
                                                        steps={["prioritize", "schedule"]}
                                                        originPath="/procurement"
                                                        basePath="/procurement/project/:projectId"
                                                    />
                                                </Layout>
                                            </Requires>
                                        )}
                                        path="/procurement/project/:projectId/prioritize"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="OPTIMIZE">
                                                <Layout>
                                                    <Schedule
                                                        steps={["prioritize", "schedule"]}
                                                        originPath="/procurement"
                                                        basePath="/procurement/project/:projectId"
                                                    />
                                                </Layout>
                                            </Requires>
                                        )}
                                        path="/procurement/project/:projectId/schedule"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="USER_GUIDE">
                                                <Requires flag="ASK_ME">
                                                    <Layout>
                                                        <UserGuideAsk />
                                                    </Layout>
                                                </Requires>
                                            </Requires>
                                        )}
                                        path="/user-guide/ask"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="USER_GUIDE">
                                                <Requires flag="REPORT">
                                                    <Layout>
                                                        <UserGuideReport />
                                                    </Layout>
                                                </Requires>
                                            </Requires>
                                        )}
                                        path="/user-guide/report"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="USER_GUIDE">
                                                <Requires flag="FORECAST">
                                                    <Layout>
                                                        <UserGuideForecast />
                                                    </Layout>
                                                </Requires>
                                            </Requires>
                                        )}
                                        path="/user-guide/forecast"
                                    />
                                    <Route
                                        element={(
                                            <Requires flag="USER_GUIDE">
                                                <Requires flag="OPTIMIZE">
                                                    <Layout>
                                                        <UserGuideOptimize />
                                                    </Layout>
                                                </Requires>
                                            </Requires>
                                        )}
                                        path="/user-guide/optimize"
                                    />
                                    <Route
                                        element={(
                                            <Layout>
                                                <ErrorContainer />
                                            </Layout>
                                        )}
                                        path="/error"
                                    />
                                    <Route
                                        path="/"
                                        element={<Navigate to={getPathFromFeatureFlag(config.feature_flag.modules[0])} />}
                                    />
                                </Routes>
                            </QueryParamProvider>
                        </ThemeProvider>
                    </LocalizationProvider>
                </AppContext.Provider>
            </Router>
        );
    }
};
