import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import ClickableDataGrid from "../../components/ClickableDataGrid";
import { formatDateTime, formatYearMonthDayLong } from "../../util/formatter";
import CircularLoading from "../../components/loading/CircularLoading";
import AdvisorContainer from "../../layout/AdvisorContainer";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { AppContext } from "../../AppRouter";
import WarningIcon from "@mui/icons-material/Warning";
import { LoadingButton } from "@mui/lab";

const Report = () => {
    const { client, config, notify, user } = useContext(AppContext);
    const navigate = useNavigate();

    const [reports, setReports] = useState();

    const [toDelete, setToDelete] = useState();
    const [deleting, setDeleting] = useState(false);

    const columns = [
        {
            field: "name",
            headerName: config.i18n.report.list.name_column,
            flex: 4,
            sortable: false,
        },
        {
            field: "created_at",
            headerName: config.i18n.report.list.created_at_column,
            flex: 3,
            sortable: false,
            renderCell: params => (
                <span title={`${config.i18n.collector.created_at} ${formatDateTime(params.value, config.locale?.language)} by ${params.row.created_by_name} `}>
                    {formatYearMonthDayLong(params.value, config.locale?.language)}
                </span>
            ),
        },
        {
            field: "actions",
            flex: 0,
            sortable: false,
            type: "actions",
            getActions: params => [
                <Tooltip key="edit" title={config.i18n.button.edit}>
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label={config.i18n.button.edit}
                        disabled={!params.row.permission?.READ}
                        data-cy="edit"
                        color="info"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        component={RouterLink}
                        to={`/report/${params.id}/edit`}
                    />
                </Tooltip>,
                <Tooltip key="duplicate" title={config.i18n.button.clone}>
                    <GridActionsCellItem
                        icon={<ContentCopyIcon />}
                        label={config.i18n.button.clone}
                        disabled={!user.permissions?.REPORT?.CREATE || !params.row.permission?.READ}
                        data-cy="clone"
                        color="info"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        component={RouterLink}
                        to={`/report/${params.id}/clone`}
                    />
                </Tooltip>,
                <Tooltip key="delete" title={config.i18n.button.delete}>
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label={config.i18n.button.delete}
                        disabled={!params.row.permission?.DELETE}
                        data-cy="delete"
                        color="error"
                        onClick={(e) => {
                            e.stopPropagation();
                            setToDelete(params.row);
                        }}
                    />
                </Tooltip>,
            ],
        },
    ];

    useEffect(() => {
        client.report.reportListReports()
            .then((reports) => {
                setReports(reports.response);
            })
            .catch((error) => {
                setReports([]);
                notify.error(error, "report.fetch");
            });
    }, []);

    if (!reports) {
        return (
            <AdvisorContainer>
                <CircularLoading flex={1} label={config.i18n.report.list.loading} />
            </AdvisorContainer>
        );
    }

    return (
        <AdvisorContainer
            title={config.i18n.report.list.title}
            actions={(
                <Button
                    data-cy="create"
                    variant="contained"
                    disabled={!user.permissions?.REPORT?.CREATE}
                    startIcon={<AddIcon />}
                    component={RouterLink}
                    to="/report/create"
                    title={config.i18n.button.create}
                >
                    {config.i18n.button.create}
                </Button>
            )}
        >
            {
                toDelete
                    ? (
                        <Dialog
                            maxWidth="xs"
                            open={!!toDelete}
                        >
                            <DialogTitle>
                                <Box display="flex" alignItems="center">
                                    <WarningIcon color="warning" sx={{ marginRight: 1 }} />
                                    {config.i18n.dialog.delete_report_title}
                                </Box>
                            </DialogTitle>
                            <DialogContent dividers>
                                <Typography>
                                    {config.i18n.dialog.delete_report + " "}
                                    <Box component="span" fontWeight="fontWeightMedium">{toDelete.name}</Box>
                                    ?
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button disabled={deleting} onClick={() => setToDelete(null)}>
                                    {config.i18n.button.cancel}
                                </Button>
                                <LoadingButton
                                    loading={deleting}
                                    loadingPosition="center"
                                    onClick={() => {
                                        setDeleting(true);
                                        client.report.reportDeleteReport(toDelete.id)
                                            .then((report) => {
                                                setReports(reports.filter(el => el.id !== report.id));
                                                setDeleting(false);
                                                setToDelete(null);
                                            })
                                            .catch((error) => {
                                                notify.error(error, "report.delete");
                                                setDeleting(false);
                                                setToDelete(null);
                                            });
                                    }}
                                    data-cy="report_dialog-delete"
                                >
                                    {config.i18n.button.delete}
                                </LoadingButton>
                            </DialogActions>
                        </Dialog>
                        )
                    : null
            }
            <ClickableDataGrid
                dataCy="reports_list"
                rows={reports}
                columns={columns}
                onRowClick={params => navigate(`/report/${params.id}`)}
            />
        </AdvisorContainer>
    );
};

export default Report;
