import { Autocomplete, TextField } from "@mui/material";
import React, { useContext } from "react";

import DimensionAutoCompleteBox from "../picker/DimensionAutoCompleteBox";
import PropTypes from "prop-types";
import HierarchyAutoCompleteBox from "../picker/HierarchyAutoCompleteBox";
import DeleteIconButton from "../button/DeleteIconButton";
import DimensionBox from "../DimensionBox";
import { Stack } from "@mui/system";
import { AppContext } from "../../AppRouter";

const DimensionPicker = ({ documentDimensions, dimension, dimensionFilters, metadata, parameters, disabled, onUpdate, onDelete }) => {
    const { client, config } = useContext(AppContext);

    function dispatchDimensionSearch(dimension, queryString, page = 0, dimsToIgnore = []) {
        const dims = { ...dimensionFilters };
        dimsToIgnore.forEach((toIgnore) => {
            delete dims[toIgnore];
        });

        return client.dimension.dimensionSearch(dimension, {
            documents: documentDimensions.documents[dimension],
            parameters: parameters,
            dimension_parameters: dims,
        },
        queryString, page, void 0);
    }

    function getAvailableOptions() {
        // the available dim options are dims
        // i) the analysis allow them.
        // ii) not already filtered.
        // iii) don't belong to CATEGORY, PURCHASE_CATEGORY, SALES_CATEGORY, LOCATION, PURCHASE_LOCATION, SALES_LOCATION, ACCOUNT_GROUP.
        return documentDimensions.dimensions
            .filter(dim => !(dim in dimensionFilters))
            .filter(dim => !(config.hierarchy.CATEGORY || []).includes(dim))
            .filter(dim => !(config.hierarchy.PURCHASE_CATEGORY || []).includes(dim))
            .filter(dim => !(config.hierarchy.PRODUCED_CATEGORY || []).includes(dim))
            .filter(dim => !(config.hierarchy.SALES_CATEGORY || []).includes(dim))
            .filter(dim => !(config.hierarchy.LOCATION || []).includes(dim))
            .filter(dim => !(config.hierarchy.PURCHASE_LOCATION || []).includes(dim))
            .filter(dim => !(config.hierarchy.SALES_LOCATION || []).includes(dim))
            .filter(dim => !(config.hierarchy.ACCOUNT_GROUP || []).includes(dim))
            .sort((dim1, dim2) => {
                const i18n1 = config.i18n.dimension[dim1] || dim1;
                const i18n2 = config.i18n.dimension[dim2] || dim2;

                return i18n1.localeCompare(i18n2);
            });
    }

    function getComponentToRender() {
        // if dimension is set and belongs to a hierarchy, render a HierarchyAutoCompleteBox.
        // else if dimension is set and doesn't belong to a hierarchy, render a DimensionAutoCompleteBox.
        // else render a box to pick the dimension.
        if (dimension && dimension in config.hierarchy) {
            const values = config.hierarchy[dimension].map(dim => dimensionFilters[dim] || []);

            return (
                <DimensionBox title={config.i18n.dimension[dimension]}>
                    <HierarchyAutoCompleteBox
                        disabled={disabled}
                        dimensions={config.hierarchy[dimension]}
                        values={values}
                        metadata={metadata}
                        onChange={(dims, vals, metadata) => {
                            const newDimFilters = dims.reduce((acc, value, index) => {
                                return { ...acc, [value]: vals[index] };
                            }, {});

                            onUpdate(dimension, newDimFilters, metadata);
                        }}
                        onQuery={(dim, queryString, dimsToIgnore, page = 0) =>
                            dispatchDimensionSearch(dim, queryString, page, dimsToIgnore)}
                        fullWidth
                    />
                </DimensionBox>

            );
        } else if (dimension) {
            return (
                <DimensionAutoCompleteBox
                    disabled={disabled}
                    dimension={dimension}
                    onChange={(dimValues) => {
                        // FIXME #1028: Standardize API.
                        const newValues = dimValues.map(dim => dim?.id || dim);
                        const newMetadata = dimValues.reduce((acc, value) => {
                            return { ...acc, [value.id]: value };
                        }, {});

                        onUpdate(dimension,
                            {
                                [dimension]: newValues,
                            }, newMetadata);
                    }}
                    onQuery={(queryString, page = 0) =>
                        dispatchDimensionSearch(dimension, queryString, page, [dimension])}
                    metadata={metadata}
                    values={dimensionFilters[dimension] || []}
                    fullWidth
                />
            );
        } else {
            return (
                <Autocomplete
                    data-cy="dimension_picker"
                    options={getAvailableOptions()}
                    getOptionLabel={option => config.i18n.dimension[option] || option}
                    size="small"
                    disableClearable
                    disablePortal
                    renderInput={params =>
                        <TextField {...params} label={config.i18n.selector.dimension} />}
                    onChange={(ev, value) => onUpdate(value)}
                />
            );
        }
    }

    return (
        <Stack direction="row" alignItems="flex-start" spacing={0.5}>
            <Stack direction="column" flex={1}>
                {getComponentToRender()}
            </Stack>
            <DeleteIconButton
                disabled={disabled}
                color="grey"
                variant="outlined"
                onClick={() => onDelete(dimension, dimension in config.hierarchy ? config.hierarchy[dimension] : [])}
            />
        </Stack>
    );
};

DimensionPicker.propTypes = {
    documentDimensions: PropTypes.object,
    dimension: PropTypes.string,
    dimensionFilters: PropTypes.object,
    metadata: PropTypes.object,
    parameters: PropTypes.object,
    disabled: PropTypes.bool,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
};

export default DimensionPicker;
