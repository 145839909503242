import { defaultValueLabelSetting, getAxisTooltip, getAxisV, getBase, getFormattedValue, getLegend, getYLineSeriesData } from "./common";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    const series = getYLineSeriesData(data).map(series => ({
        type: "line",
        data: series,
        label: defaultValueLabelSetting("top", baseFontSize, config,
            value => getFormattedValue(value.data.value, "y", data, config)),
        connectNulls: true,
    }));

    return {
        ...getBase(title, baseFontSize),
        ...getAxisV(data, config, baseFontSize),
        ...getAxisTooltip(data, config, baseFontSize),
        ...getLegend(data, config, baseFontSize),
        series: series,
    };
};
